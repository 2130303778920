import { TRACKING_EVENTS } from '@/lib/tracking/const';
import type { ClickedName, UserAuthType, ViewedItemName } from '@/lib/tracking/type';
import { QUANTITY_NUMBER, type EcommerceData, type TrackingSendData } from '@/lib/tracking/type';

const sendTrackingEvent = (data: TrackingSendData) => {
  try {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer?.push({ ecommerce: null });
    window.dataLayer?.push(data);
  } catch (err: unknown) {
    if (err instanceof Error) {
      console.error(data.event, err.stack);
    }
  }
};

export const sendTrackingViewSuperMessage = (data: {
  channelId: string;
  artistId: string;
  artistName: string;
  performanceId: string;
  performanceDisplayName: string;
}) => {
  const { channelId, artistId, artistName, performanceId, performanceDisplayName } = data;
  sendTrackingEvent({
    event: TRACKING_EVENTS.VIEW_SUPER_MESSAGE,
    channel_id: channelId,
    artist_id: artistId,
    artist_name: artistName,
    performance_id: performanceId,
    performance_display_name: performanceDisplayName,
  });
};

export const sendTrackingPurchase = (superMessageBadgeId: string, ecommerce: EcommerceData) => {
  const { transactionId, value, items } = ecommerce;

  sendTrackingEvent({
    event: TRACKING_EVENTS.PURCHASE,
    items: [
      {
        item_name: items.itemName,
        item_id: items.itemId,
        item_brand: items.itemBrand,
        price: items.price,
        quantity: QUANTITY_NUMBER,
      },
    ],
    ecommerce: {
      transaction_id: transactionId,
      currency: 'JPY',
      value,
      items: [
        {
          item_name: items.itemName,
          item_id: items.itemId,
          item_brand: items.itemBrand,
          price: items.price,
          quantity: QUANTITY_NUMBER,
        },
      ],
    },
    super_message_badge_id: superMessageBadgeId,
  });
};

export const sendTrackingClickCommon = (clickedName: ClickedName) => {
  sendTrackingEvent({
    event: TRACKING_EVENTS.CLICK_COMMON,
    clicked_name: clickedName,
  });
};

export const sendTrackingViewItem = (viewedItemName: ViewedItemName) => {
  sendTrackingEvent({
    event: TRACKING_EVENTS.VIEW_ITEM,
    viewed_item_name: viewedItemName,
  });
};

export const sendTrackingUserAuth = (part: UserAuthType) => {
  sendTrackingEvent({
    event: TRACKING_EVENTS.USER_AUTH,
    clicked_name: part,
  });
};

export const sendTrackingSignUp = () => {
  sendTrackingEvent({
    event: TRACKING_EVENTS.SIGN_UP,
    clicked_name: 'sign_up',
  });
};

export const sendTrackingTicketUpload = () => {
  sendTrackingEvent({
    event: TRACKING_EVENTS.TICKET_UPLOAD,
    clicked_name: 'ticket_upload',
  });
};

export const sendTrackingSelectTicket = () => {
  sendTrackingEvent({
    event: TRACKING_EVENTS.SELECT_TICKET,
    clicked_name: 'select_ticket',
  });
};

export const sendTrackingTicketUploadComplete = () => {
  sendTrackingEvent({
    event: TRACKING_EVENTS.TICKET_UPLOAD_COMPLETE,
    clicked_name: 'ticket_upload_complete',
  });
};
