export const TRACKING_EVENTS = {
  VIEW_SUPER_MESSAGE: 'view_super_message',
  PURCHASE: 'purchase',
  CLICK_COMMON: 'click_common',
  VIEW_ITEM: 'view_item',
  USER_AUTH: 'user_auth',
  SIGN_UP: 'sign_up',
  TICKET_UPLOAD: 'ticket_upload',
  SELECT_TICKET: 'select_ticket',
  TICKET_UPLOAD_COMPLETE: 'ticket_upload_complete',
  MESSAGE_POST_BUTTON: 'message_post_button',
  SUPER_MESSAGE_CONFIRM_BUTTON: 'super_message_confirm_button',
};
