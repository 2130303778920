import type { TRACKING_EVENTS } from '@/lib/tracking/const';

export const QUANTITY_NUMBER = 1;
type TrackingEvents = typeof TRACKING_EVENTS;

type ViewSupperMessage = {
  event: TrackingEvents['VIEW_SUPER_MESSAGE'];
  channel_id: string;
  artist_id: string;
  artist_name: string;
  performance_id: string;
  performance_display_name: string;
};

type Purchase = {
  event: TrackingEvents['PURCHASE'];
  items: [
    {
      item_name: string;
      item_id: string;
      item_brand: string;
      price: number;
      quantity: 1;
    },
  ];
  ecommerce: {
    transaction_id: string;
    value: number;
    currency: 'JPY';
    items: [
      {
        item_name: string;
        item_id: string;
        item_brand: string;
        price: number;
        quantity: 1;
      },
    ];
  };
  super_message_badge_id: string;
};

export type EcommerceData = {
  transactionId: string;
  value: number;
  items: {
    itemName: string;
    itemId: string;
    itemBrand: string;
    price: number;
  };
};

export type ClickedName =
  | 'credit_card_registration_cancel'
  | 'credit_card_registration_button'
  | 'super_message_post_button'
  | 'super_message_icon'
  | 'message_input'
  | 'sign_up'
  | 'ticket_upload'
  | 'select_ticket'
  | 'ticket_upload_complete'
  | 'message_post_button'
  | 'super_message_confirm_button'
  | UserAuthType;

export type ClickCommonData = {
  event: TrackingEvents['CLICK_COMMON'];
  clicked_name: ClickedName;
};

export type ViewedItemName = 'credit_card_registration_appeal_modal';

export type ViewItemData = {
  event: TrackingEvents['VIEW_ITEM'];
  viewed_item_name: ViewedItemName;
};

export type UserAuthType =
  | 'user_auth_menu'
  | 'user_auth_performance'
  | 'user_auth_ticket_upload'
  | 'user_auth_channel'
  | 'user_auth_follow';

export type TrackingSendData = ViewSupperMessage | Purchase | ClickCommonData | ViewItemData;
